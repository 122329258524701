<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">Список событий</h3>
      </div>
      <div class="card-toolbar">
        <a href="#" @click="openModal()">Добавить</a>
      </div>
    </div>
    <div class="card-body">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <v-server-table
          :url="url"
          :columns="columns"
          :options="options"
          :ref="tableName"
        >
          <div slot="active" slot-scope="props">
            <span v-if="props.row.active == 1" class="text-success">
              Активна</span
            >
            <span v-if="props.row.active == 0" class="text-danger">
              Не активна</span
            >
          </div>
          <div slot="created_at" slot-scope="props">
            {{ props.row.created_at | moment("DD.MM.YY HH:mm") }}
          </div>
          <div
            class="text-center"
            slot="actions"
            slot-scope="props"
            style="width: 100px"
          >
            <div class="dropdown dropdown-inline">
              <BaseTableEditBtn @click="editEntity(props.row)" />
              <BaseTableDeleteBtn @click="deleteEntity(props.row.id)" />
            </div>
          </div>
        </v-server-table>
      </div>
    </div>
    <modal :name="modalName" :height="'auto'" :scrollable="true">
      <EntityForm :entity="entity" @onUpdated="$refs[tableName].refresh()" />
    </modal>
  </div>
</template>

<script>
import { vueTableMixin } from "@/views/mixins/vueTableMixin";
const EntityForm = () => import("@/components/events/EntityForm");

export default {
  name: "Index",
  mixins: [vueTableMixin],
  components: {
    EntityForm,
  },

  data() {
    return {
      modalName: "events",
      tableName: "events",
      url: "events",
      columns: ["id", "title", "short_description", "active", "actions"],
      options: {
        headings: {
          title: "Название",
          short_description: "Краткое описание",
          active: "Статус",
          actions: "Действия",
        },
        filterable: false,
      },
    };
  },
  methods: {
    save() {
      this.$refs[this.tableName].refresh();
      this.entity = null;
    },
  },
};
</script>